import React from "react";

const Welcome = () => {
  return (
    <div className="container">
      <div className="max-w-[809px] mx-auto w-full flex flex-col items-center sm:space-y-7 space-y-4">
        <h1 className="text-white font-display font-black text-center sm:text-[44px] lg:text-[54px] text-[28px] leading-[1.3] ">
          {" "}
          Web3’s first
          <br />
          <span className="font-display text-customRed drop-shadow-[0px_4px_4px_rgba(0, 0, 0, 0.95)]">
            FRAMES
          </span>{" "}
          for your NFT
        </h1>

        <p className="text-center sm:text-[20px] lg:text-[28px] text-base text-white">
          A collection of Frames for your exclusive art
        </p>

        <button className="font-display text-base text-black bg-yellow-red-gradient py-[12px] sm:py-[20px] px-[20px] sm:px-[30px] sm:rounded-[14px] rounded-[5px]">
          Join our discord
        </button>
      </div>
    </div>
  );
};

export default Welcome;
