type NftType = {
  id: {
    tokenId: string;
  };
  contract: {
    address: string;
  };
};

const getAddressNFTs = async (
  endpoint: string,
  owner: string,
  contractAddress: string,
  retryAttempt = 0
) => {
  if (retryAttempt === 5) {
    return;
  }
  if (owner) {
    let data;
    try {
      if (contractAddress) {
        data = await fetch(
          `${endpoint}/getNFTs?owner=${owner}&contractAddresses%5B%5D=${contractAddress}`
        ).then((data) => data.json());
      } else {
        // data = await fetch(`${endpoint}/v1/getNFTs?owner=${owner}`).then(data => data.json())
        data = await fetch(`${endpoint}/getNFTs?owner=${owner}`).then((data) =>
          data.json()
        );
      }
      // console.log("GETNFTS: ", data)
    } catch (e) {
      getAddressNFTs(endpoint, owner, contractAddress, retryAttempt + 1);
    }

    return data;
  }
};

const getEndpoint = (chain: string) => {
  switch (chain) {
    case "Ethereum":
      return process.env.REACT_APP_ALCHEMY_ETHEREUM_ENDPOINT;
    case "Polygon":
      return process.env.REACT_APP_ALCHEMY_POLYGON_ENDPOINT;
    case "Mumbai":
      return process.env.REACT_APP_ALCHEMY_MUMBAI_ENDPOINT;
    case "Rinkeby":
      return process.env.REACT_APP_ALCHEMY_RINKEBY_ENDPOINT;
    default:
      return process.env.REACT_APP_ALCHEMY_ETHEREUM_ENDPOINT;
  }
};

const fetchNFTs = async (
  owner: string,
  setNFTs: any,
  chain = "Ethereum",
  contractAddress: string
) => {
  let endpoint: string = getEndpoint(chain) || "";
  const data = await getAddressNFTs(endpoint, owner, contractAddress);
  if (data.ownedNfts.length) {
    // const NFTs = await getNFTsMetadata(data.ownedNfts, endpoint);
    // console.log("NFTS metadata", NFTs);
    // let fullfilledNFTs = NFTs.filter((NFT) => NFT.status == "fulfilled");
    // console.log("NFTS", fullfilledNFTs);
    return data.ownedNfts;
  } else {
    setNFTs(null);
  }
};

const getNFTsMetadata = async (NFTS: [], endpoint: string) => {
  const NFTsMetadata = await Promise.allSettled(
    NFTS.map(async (NFT: NftType) => {
      const metadata = await fetch(
        `${endpoint}/getNFTMetadata?contractAddress=${NFT.contract.address}&tokenId=${NFT.id.tokenId}`
      ).then((data) => data.json());
      let image;
      console.log("metadata", metadata);
      if (metadata.media[0].uri.gateway.length) {
        image = metadata.media[0].uri.gateway;
      } else {
        image = "https://via.placeholder.com/500";
      }

      return {
        id: NFT.id.tokenId,
        contractAddress: NFT.contract.address,
        image,
        title: metadata.metadata.name,
        description: metadata.metadata.description,
        attributes: metadata.metadata.attributes,
      };
    })
  );

  return NFTsMetadata;
};

export { fetchNFTs, getAddressNFTs, getNFTsMetadata };
