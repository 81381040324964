import React from "react";
import { useAccount, useConnect } from "wagmi";
import logo from "assets/images/logo.png";
import { GrFormClose } from "react-icons/gr";
import { GiHamburgerMenu } from "react-icons/gi";
import OutsideClickDetector from "hooks/OutsideClickDetector";

const Navbar = () => {
  const [{ data: accountData }] = useAccount();
  const [{ data }, connect] = useConnect();

  const [toggleMenu, setToggleMenu] = React.useState(false);

  const sidebarRef = OutsideClickDetector(() => {
    setToggleMenu(false);
  });

  return (
    <nav className="container py-8 flex items-center justify-between ">
      <div>
        <img
          src={logo}
          className="xl:w-[176px]  sm:w-[130px] w-[110px]"
          alt=""
        />
      </div>

      <div className={`black-screen ${toggleMenu ? "show" : ""}`}></div>

      <div
        className={`flex item-center lg:justify-center lg:flex-row xl:space-x-[34px] lg:p-0 lg:space-y-0 lg:space-x-[20px] lg:static lg:w-auto lg:bg-transparent  lg:h-auto fixed top-0 right-0 w-[300px] bg-white h-full flex-col space-x-0 space-y-4 p-8 transition-all duration-[.3s] z-[110] ease-[ease] lg:translate-x-0 ${
          toggleMenu ? "translate-x-0" : "translate-x-[300px]"
        }`}
        ref={sidebarRef}
      >
        <button
          className="cursor-pointer lg:hidden ml-[-6px] mb-2 w-fit"
          onClick={() => setToggleMenu(false)}
        >
          <GrFormClose size={30} />
        </button>
        <a
          href="#About"
          className="cursor-pointer lg:text-white text-black xl:text-base lg:text-[14px] font-bold uppercase text-[18px] w-fit lg:w-auto"
        >
          About
        </a>
        <a
          href="/"
          className="cursor-pointer lg:text-white text-black xl:text-base lg:text-[14px] font-bold uppercase text-[18px] w-fit lg:w-auto"
        >
          Framing
        </a>
        <a
          href="#RoadMap"
          className="cursor-pointer lg:text-white text-black xl:text-base lg:text-[14px] font-bold uppercase text-[18px] w-fit lg:w-auto"
        >
          RoadMap
        </a>
        <a
          href="#Team"
          className="cursor-pointer lg:text-white text-black xl:text-base lg:text-[14px] font-bold uppercase text-[18px] w-fit lg:w-auto"
        >
          Team
        </a>
        <a
          href="/"
          className="cursor-pointer lg:text-white text-black xl:text-base lg:text-[14px] font-bold uppercase text-[18px] w-fit lg:w-auto"
        >
          FAQ
        </a>

        <div className="w-full ">
          <button className="w-full mt-5 lg:hidden block py-[10px] px-[18px] rounded-[35px] font-display text-[14px] bg-yellow-red-gradient text-white capitalize font-black">
            Connect Wallet
          </button>
        </div>
      </div>
      <div className="flex items-center space-x-4">
        {accountData ? (
          <div className="flex items-center">
            <div className="flex mr-4 text-white gap-4">
              <div className="flex items-center">
                <p className="">
                  {accountData.address.slice(0, 6)}...
                  {accountData.address.slice(37)}
                </p>
              </div>
            </div>
          </div>
        ) : (
          data.connectors.map((x) => (
            <button
              onClick={() => connect(x)}
              className="xl:py-[12px] xl:px-[24px] xl:text-base sm:py-[10px] py-[8px] sm:px-[18px] px-[14px] rounded-[35px] font-display text-[11px] sm:text-[14px] bg-yellow-red-gradient text-white capitalize font-black"
            >
              Connect Wallet
            </button>
          ))
        )}
        <button
          className="lg:hidden block cursor-pointer text-[25px] sm:text-[30px]"
          onClick={() => setToggleMenu(!toggleMenu)}
        >
          <GiHamburgerMenu color="white" />
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
